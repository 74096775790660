import "./bootstrap";
import "../css/app.css";

import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import * as Sentry from "@sentry/react";
import { IntlProvider } from "react-intl";
import messages_en from "./locales/en.json";
import messages_es from "./locales/es.json";
import { PostHogProvider } from "posthog-js/react";

const appName = import.meta.env.VITE_APP_NAME || "My Place";
Sentry.init({
    dsn: "https://7a807367a64ec5b68dfada124980bcd9@o4507679403540480.ingest.de.sentry.io/4507696606871632",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
            triggerLabel: "Send Feedback",
            submitButtonLabel: "Submit Feedback",
            showBranding: false,
            formTitle:"Feedback",
            messagePlaceholder: "What do you think of the platform so far? Did you experience any bugs?",
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const messages = {
    en: messages_en,
    es: messages_es,
};
const language = navigator.language.split(/[-_]/)[0];
const supportedLanguages = ["en", "es"];    
const selectedLanguage = supportedLanguages.includes(language) ? language : 'en';

const options = {
    api_host: "https://posthog.myplace.casa",
};

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.jsx`,
            import.meta.glob("./Pages/**/*.jsx")
        ),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(
            <IntlProvider
                locale={selectedLanguage}
                messages={messages[selectedLanguage]}
            >
                <PostHogProvider
                    apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
                    options={options}
                >
                    <App {...props} />
                </PostHogProvider>
            </IntlProvider>
        );
    },
    progress: {
        color: "#4B5563",
    },
});
